<template>
  <v-card>
    <v-card-text>
      <v-container>
        <v-text-field v-model="localValue.title" :label="$t('common.title')" type="text" />

        <v-select v-model="localValue.group" :label="$t('common.category')" :items="group_options" />

        <v-text-field v-model="localValue.description" :label="$t('common.short_description')" type="text" />

        <v-text-field v-model="localValue.default.video" :label="$t('common.video_link')" type="text" :rules="video_rules" @change="check_video" />

        <p>{{ $t("common.instructions") }}</p>
        <quillEditor v-model="localValue.instructions" :options="quill_config" style="height: 200px; margin-bottom: 20px" />
      </v-container>
    </v-card-text>

    <v-card-actions>
      <v-spacer />
      <v-btn color="primary" @click="save">{{ $t("common.save") }}</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import urlParser from "js-video-url-parser";

import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";

import { quillEditor } from "vue-quill-editor";
import { category_items } from "../lib/category";

export default {
  components: { quillEditor },
  props: {
    value: {
      type: Object,
      required: true,
    },
  },
  data() {
    let data = {
      video_rules: [],
      quill_config: {
        placeholder: this.$t("common.instructions"),
        modules: {
          toolbar: [
            ["bold", "italic", "underline", "strike"], // toggled buttons
            ["blockquote", "code-block"],

            [{ header: 1 }, { header: 2 }], // custom button values
            [{ list: "ordered" }, { list: "bullet" }],

            ["clean"], // remove formatting button
          ],
        },
      },
      group_options: category_items(this, true, window.app_config.id),
    };
    return data;
  },
  computed: {
    localValue: {
      get() {
        let value = this.value;
        value.default = value.default || {};
        return value;
      },
      set(localValue) {
        if (!localValue.default) {
          localValue.default = {};
        }
        this.$emit("input", localValue);
      },
    },
  },
  mounted() {},
  methods: {
    check_video() {
      if (this.localValue.default.video !== "") {
        let video_url = this.localValue.default.video;
        if (video_url.endsWith(".mp4") || video_url.endsWith(".webm")) {
          this.video_rules = [];
        } else {
          let parsed = urlParser.parse(this.localValue.default.video);
          if (!parsed) {
            this.video_rules = [this.$t("common.currently_support_videos")];
            return;
          }
          if (parsed.provider != "youtube" && parsed.provider != "vimeo") {
            this.video_rules = [this.$t("common.currently_support_videos")];
            return;
          }
          this.video_rules = [];
          video_url = urlParser.create({
            videoInfo: parsed,
            format: "embed",
          });
        }
      } else if (this.localValue.default.video === "") {
        this.video_rules = [];
      }
    },
    save() {
      this.$emit("save");
    },
  },
};
</script>

<style>
#invite_edit_modal .v-messages__message {
  line-height: normal;
}

.ql-editor {
  font-size: large;
}

.theme--dark .ql-snow .ql-stroke {
  stroke: gray;
}

.theme--light .ql-editor p {
  color: black;
}

/* RTL languages */
html[lang="he"] .ql-editor,
html[lang="ar"] .ql-editor {
  text-align: right;
}
</style>
