<template>
  <div>
    <div v-if="assessment_ids.length > 0" class="ma-6">
      <v-layout row>
        <div v-for="assessment_id in assessment_ids" class="ml-6">
          <v-btn
            v-if="grid_sets[assessment_id]"
            :href="'/api/assessment/' + assessment_id + '/summary/' + clinic.id + '/report.csv?app=' + app_config.id"
            target="_blank"
          >
            <v-icon left>mdi-download</v-icon>{{ grid_sets[assessment_id].title }} Summary CSV
          </v-btn>
        </div>
      </v-layout>
    </div>
    <v-data-table :headers="headers" :items="assessment_data" item-key="assessment_instance_id">
      <template v-slot:item.date="{ item }">{{ display_date(item.date) }}</template>

      <template v-slot:item.assessment_instance_id="{ item }">
        <template>
          <v-btn :to="'/app/assessment_report/' + item.assessment_id + '/' + item.invite_id + '/' + item.assessment_instance_id" class="mr-1" x-small>
            <v-icon left small>mdi-eye</v-icon>View
          </v-btn>

          <v-btn :disabled="print_inflight[item.assessment_instance_id]" class="mr-1" x-small @click="print(item)">
            <v-progress-circular v-if="print_inflight[item.assessment_instance_id]" indeterminate :size="15"></v-progress-circular>
            <v-icon v-else small>mdi-printer</v-icon>&nbsp;&nbsp;Print
          </v-btn>

          <v-btn :disabled="print_inflight[item.assessment_instance_id]" class="mr-1" x-small @click="download(item)">
            <v-icon small>mdi-download</v-icon>&nbsp;&nbsp;Download
          </v-btn>

          <v-btn :disabled="email_inflight[item.assessment_instance_id]" class="mr-1" x-small @click="email(item)">
            <v-progress-circular v-if="email_inflight[item.assessment_instance_id]" indeterminate :size="15"></v-progress-circular>
            <v-icon v-else small>mdi-send</v-icon>&nbsp;&nbsp;Email
          </v-btn>

          <v-btn class="mr-1" x-small @click="delete_assessment(item)"> <v-icon small>mdi-delete</v-icon>&nbsp;&nbsp;Delete </v-btn>
        </template>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import axios from "axios";
import { mapState } from "vuex";
import Vue from "vue";
import printJS from "print-js";

export default {
  props: {
    clinic: {
      type: Object,
      required: true,
    },
  },
  data: () => {
    return {
      assessment_results: {},
      grid_sets: {},
      assessment_ids: [],
      invites: {},
      print_inflight: {},
      email_inflight: {},
      invites_loaded: false,
      assessments_loaded: false,
    };
  },
  computed: {
    ...mapState(["app_config"]),
    headers() {
      let headers = [
        {
          text: "Date",
          value: "date",
        },
        {
          text: "Assessment",
          value: "assessment_title",
        },
        {
          text: "First Name",
          value: "first_name",
        },
        {
          text: "Last Name",
          value: "last_name",
        },
        {
          text: "",
          value: "assessment_instance_id",
        },
        {
          text: "",
          value: "tags",
        },
      ];

      return headers;
    },
    assessment_data() {
      let assessments = [];

      if (!this.invites_loaded || !this.assessments_loaded) {
        return assessments;
      }

      for (let assessment_id of this.assessment_ids) {
        for (let res of this.assessment_results[assessment_id]) {
          let assessment = {
            assessment_id: assessment_id,
            assessment_title: this.grid_sets[assessment_id].title,
            assessment_instance_id: res.details.assessment_instance_id,
            date: res.created,
            tags: "",
            first_name: this.invites[res.invite_id].name,
            last_name: this.invites[res.invite_id].name_family,
            invite_id: res.invite_id,
            activity_id: res.id,
          };
          assessments.push(assessment);
        }
      }

      return assessments;
    },
  },
  methods: {
    display_date(date) {
      let parsed = new Date(date + "Z");
      return parsed.toLocaleString("en-US", {
        year: "numeric",
        month: "long",
        day: "numeric",
        hour: "numeric",
        minute: "numeric",
        hour12: true,
      });
    },
    email(item) {
      Vue.set(this.email_inflight, item.assessment_instance_id, true);

      let email_uri = "/api/assessment/" + item.assessment_id + "/reports/" + this.clinic.id + "/" + item.assessment_instance_id + "/email_pdf";
      axios.post(email_uri).then((response) => {
        let resp = response.data;
        if (resp.status == "success") {
          messageBus.$emit("success", "Email sent");
        } else {
          messageBus.$emit("api-error", resp);
        }
        Vue.set(this.email_inflight, item.assessment_instance_id, false);
      });
    },
    delete_assessment(item) {
      if (window.confirm("Are you sure you want to delete this assessment?")) {
        axios.delete("/api/activity/" + item.activity_id).then((response) => {
          let resp = response.data;
          if (resp.status == "success") {
            messageBus.$emit("success", "Assessment deleted");
          } else {
            messageBus.$emit("api-error", resp);
          }
          this.load();
        });
      }
    },
    print(item) {
      // Format the filename from the user's first name and last name
      // Remove all non alphanumeric characters and replace spaces with underscores
      let first_name = item.first_name.replaceAll(/[^a-zA-Z0-9\s]/g, "").replaceAll(/\s/g, "_");
      let last_name = item.last_name.replaceAll(/[^a-zA-Z0-9\s]/g, "").replaceAll(/\s/g, "_");
      let filename = first_name + "_" + last_name + "_assessment_report.pdf";

      let pdf_path = "/app/assessment_report/" + item.assessment_id + "/" + item.invite_id + "/" + item.assessment_instance_id;
      let uri =
        "/api/pdf/print?path=" +
        encodeURIComponent(pdf_path) +
        "&cache_buster=" +
        Math.round(Math.random() * 1000000) +
        "&filename=" +
        filename +
        "&assessment=" +
        item.assessment_id;

      if (this.app_config.assessments && this.app_config.assessments[item.assessment_id]) {
        let assessment_config = this.app_config.assessments[item.assessment_id];
        if (assessment_config.paper_format) {
          uri += "&paper_format=" + assessment_config.paper_format;
        }
      }

      printJS({
        printable: uri,
        onLoadingStart: () => {
          Vue.set(this.print_inflight, item.assessment_instance_id, true);
        },
        onLoadingEnd: () => {
          Vue.set(this.print_inflight, item.assessment_instance_id, false);
        },
      });
    },
    download(item) {
      let pdf_path = "/app/assessment_report/" + item.assessment_id + "/" + item.invite_id + "/" + item.assessment_instance_id;
      let download_uri = "/api/pdf/download?path=" + encodeURIComponent(pdf_path) + "&cache_buster=" + Math.round(Math.random() * 1000000);

      download_uri += "&assessment=" + item.assessment_id;

      window.open(download_uri, "_blank");
    },
    load() {
      if (!this.app_config.assessments) return;

      // Load invites
      axios.get("/api/clinic/" + this.clinic.id + "/invites").then((response) => {
        let resp = response.data;
        if (resp.status == "success") {
          for (let invite of resp.data) {
            Vue.set(this.invites, invite.id, invite);
          }
        } else {
          messageBus.$emit("api-error", resp);
        }
        this.invites_loaded = true;
      });

      axios.get("/api/assessment/activity_by_clinic/" + this.clinic.id).then((response) => {
        let resp = response.data;
        if (resp.status == "success") {
          for (let assessment_result of resp.data) {
            if (!this.assessment_results[assessment_result.details.assessment_id]) {
              Vue.set(this.assessment_results, assessment_result.details.assessment_id, []);
            }
            this.assessment_results[assessment_result.details.assessment_id].push(assessment_result);
          }
        } else {
          messageBus.$emit("api-error", resp);
        }
        this.assessments_loaded = true;
      });

      for (let grid_set_id of Object.keys(this.app_config.assessments)) {
        axios.get("/api/grid_set/" + grid_set_id).then((response) => {
          let resp = response.data;
          if (resp.status == "success") {
            Vue.set(this.grid_sets, grid_set_id, resp.data);
            this.assessment_ids.push(grid_set_id);
          } else {
            messageBus.$emit("api-error", resp);
          }
        });
      }
    },
  },
};
</script>
