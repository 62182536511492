<template>
  <v-card>
    <v-card-text>
      <v-layout>
        <v-flex md4>
          <ExercisePresetSelect
            :label="$t('common.presets')"
            :current_exercise_config="localValue.config"
            :instructions="localValue.instructions"
            :exercise_id="localValue.exercise_id"
            :preset_id="preset ? preset.id : null"
            @preset_selected="preset_selected"
          />
        </v-flex>

        <v-flex xs5 offset-xs3>
          <v-select v-model="localValue.customization" outlined :label="$t('common.customization')" :items="customization_options" />
        </v-flex>
      </v-layout>

      <v-layout>
        <v-flex xs4>
          <v-text-field v-model="localValue.title" outlined :label="$t('common.title')" :hint="$t('regimen_exercise_edit.title_hint')" />
        </v-flex>

        <v-flex xs7 offset-xs1>
          <v-textarea
            v-if="localValue.exercise_id"
            v-model="localValue.instructions"
            outlined
            auto-grow
            rows="2"
            :label="$t('common.special_instructions')"
            :hint="$t('common.special_instructions_for_exercise')"
            @change="$emit('change', value)"
          />
        </v-flex>
      </v-layout>

      <v-layout v-if="app_config.exercise_education">
        <v-flex xs12>
          <quillEditor v-model="localValue.exercise_education" :options="quill_config" style="height: 100px; margin-bottom: 40px" />
        </v-flex>
      </v-layout>

      <v-divider v-if="has_levels" class="mb-8" />

      <v-layout v-if="has_levels" style="margin-bottom: -15px">
        <v-flex md3>
          <v-select
            v-model="localValue.level"
            outlined
            dense
            :items="level_select"
            :hint="$t('regimen_exercise_edit.user_will_start_at_this_level')"
            :label="$t('common.start_level')"
            persistent-hint
            @change="level_change"
          />
        </v-flex>

        <v-flex md3 offset-md1>
          <v-select
            v-model="localValue.max_level"
            outlined
            dense
            :items="max_level_select"
            :hint="$t('regimen_exercise_edit.max_level_hint')"
            :label="$t('regimen_exercise_edit.max_level')"
            persistent-hint
          />
        </v-flex>

        <v-flex md3 offset-md1 style="margin-top: -20px">
          <v-checkbox
            v-model="localValue.automatic_levelling"
            :label="$t('regimen_exercise_edit.automatic_levelling')"
            :hint="automatic_levelling_hint"
            persistent-hint
          ></v-checkbox>
        </v-flex>
      </v-layout>

      <v-alert v-if="current_exercise.level_deprecated" style="margin-top: 25px; margin-bottom: -10px; font-size: small" outlined dense type="warning">{{
        $t("regimen_exercise_edit.level_deprecated")
      }}</v-alert>

      <v-divider style="margin-top: 40px; margin-bottom: 20px" />

      <div v-if="localValue.exercise_id" style="margin-left: -10px">
        <component :is="localValue.exercise_id" ref="exerciseConfig" v-model="localValue.config" regimen_config @change="$emit('change', value)" />
      </div>
    </v-card-text>

    <v-alert v-for="error in errors" :key="error" :value="true" type="error">{{ error }}</v-alert>

    <v-card-actions>
      <v-spacer />
      <v-btn v-if="localValue.exercise_id" color="primary" @click="save">{{ $t("common.save") }}</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import OpticalGymConfigForms from "../../../exercises/OpticalGymConfigForms.js";
import SelectExercise from "./SelectExercise";
import { mapState } from "vuex";
import Vue from "vue";

import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";

import { quillEditor } from "vue-quill-editor";

export default {
  components: Object.assign(OpticalGymConfigForms, {
    SelectExercise,
    quillEditor,
  }),
  props: {
    value: {
      type: Object,
      required: true,
    },
    allow_select_exercise: {
      type: Boolean,
      default: () => true,
    },
  },
  data() {
    let data = {
      errors: [],
      preset: null,
      customization_options: [
        {
          text: this.$t("regimen_exercise_edit.dont_allow_customization_no_levelling"),
          value: "no_customization_no_levelling",
        },
        {
          text: this.$t("regimen_exercise_edit.dont_allow_customization"),
          value: "no_customization",
        },
        {
          text: this.$t("regimen_exercise_edit.allow_basic_customization"),
          value: "basic",
        },
        {
          text: this.$t("regimen_exercise_edit.allow_advanced_customization"),
          value: "advanced",
        },
      ],
      quill_config: {
        placeholder: this.$t("common.education"),
        modules: {
          toolbar: [
            ["bold", "italic", "underline", "strike"], // toggled buttons
            ["blockquote", "code-block"],

            [{ header: 1 }, { header: 2 }], // custom button values
            [{ list: "ordered" }, { list: "bullet" }],

            ["clean"], // remove formatting button
          ],
        },
      },
    };
    return data;
  },
  computed: {
    ...mapState(["exercises", "app_config"]),
    localValue: {
      get() {
        let value = this.value;
        if (value.exercise_id && Object.entries(value.config).length === 0) {
          value.config = JSON.parse(JSON.stringify(this.current_exercise.global));
        }

        // Automatic levelling should be ON by default
        if (value.automatic_levelling === undefined) {
          if (this.exercises[value.exercise_id].level_deprecated) {
            value.automatic_levelling = false;
          } else {
            value.automatic_levelling = true;
          }
        }

        // customization should be set to "no_customization" by default
        if (!value.customization) {
          if (this.exercises[value.exercise_id].level_deprecated) {
            value.customization = "no_customization_no_levelling";
          } else {
            value.customization = "no_customization";
          }
        }

        // title should be set to the exercise title by default
        if (!value.title) {
          if (this.exercises[value.exercise_id].title) {
            let locale = this.$i18n.lang() || "en";
            value.title = this.exercises[value.exercise_id].title[locale];
          }
        }

        let exercise = this.exercises[value.exercise_id];
        let exercise_config_form = OpticalGymConfigForms[value.exercise_id];
        let current_config_version = value.config_version || 0;

        // Upgrade the config if needed
        if (
          current_config_version < exercise.config_version &&
          exercise_config_form &&
          exercise_config_form.methods &&
          exercise_config_form.methods.upgrade_config
        ) {
          exercise_config_form.methods.upgrade_config(value.config, current_config_version, exercise.config_version);
        }
        value.config_version = exercise.config_version;

        // Preprocess the config if OpticalGymConfigForms has a config preprocessor
        if (exercise_config_form && exercise_config_form.methods && exercise_config_form.methods.preprocess_config) {
          OpticalGymConfigForms[value.exercise_id].methods.preprocess_config(value.config);
        }

        return value;
      },
      set(localValue) {
        this.$emit("input", localValue);
      },
    },
    has_levels: function () {
      if (this.localValue.exercise_id) {
        return this.exercises[this.localValue.exercise_id].num_levels > 1;
      }
      return false;
    },
    level_select: function () {
      let levels = [];
      if (this.localValue.exercise_id) {
        let exercise = this.exercises[this.localValue.exercise_id];
        for (let i = 1; i <= exercise.num_levels; i++) {
          levels.push({ value: i, text: this.$t("common.level_list") + i });
        }
      }
      return levels;
    },
    max_level_select: function () {
      let levels = [];
      if (this.localValue.exercise_id) {
        let exercise = this.exercises[this.localValue.exercise_id];
        for (let i = this.localValue.level; i <= exercise.num_levels; i++) {
          levels.push({ value: i, text: this.$t("common.level_list") + i });
        }
      }
      levels.reverse();
      return levels;
    },
    automatic_levelling_hint() {
      return this.$t("regimen_exercise_edit.if_checked_user_progress");

      // TODO: fix reactivity
      //if (this.localValue.automatic_levelling) {
      //  return "The user will automatically progress to the next level when they reach the requisite score.";
      //} else {
      //  return "You will need to manually change the user's level.";
      //}
    },
    current_exercise() {
      return this.exercises[this.localValue.exercise_id];
    },
  },
  methods: {
    preset_selected(preset) {
      if (preset) {
        Object.assign(this.localValue.config, preset.config);
        this.localValue.instructions = preset.instructions;
      } else {
        // NULL preset, meaning default config
        let assign_config = JSON.parse(JSON.stringify(this.current_exercise.global));

        // Delete level and texturl
        // TODO: texturl et al should be defined in the exercise config params yml
        if ("level" in assign_config) {
          delete assign_config.level;
        }
        if ("texturl" in assign_config) {
          delete assign_config.texturl;
        }

        Object.assign(this.localValue.config, assign_config);
        this.localValue.instructions = "";

        // Apply local level config
        if (this.current_exercise.num_levels && this.localValue.config.level) {
          Object.assign(this.localValue.config, this.current_exercise["level" + this.config.level]);
        }
      }

      // Check if we should update the title (this.preset is currently the PREVIOUS preset, or null if no preset was selected)
      let exercise_title = this.current_exercise.title[this.$i18n.lang()];
      let check_title = exercise_title;
      if (this.preset) {
        check_title = exercise_title + " - " + this.preset.title;
      }
      if (!this.localValue.title || this.localValue.title.trim() === check_title) {
        if (preset) {
          this.localValue.title = exercise_title + " - " + preset.title;
        } else {
          this.localValue.title = exercise_title;
        }
      }

      this.preset = preset;
      this.$forceUpdate();
      this.$emit("change", this.localValue);
    },
    level_change(new_level) {
      // Override config with level config
      let exercise = this.exercises[this.localValue.exercise_id];
      let level_config = exercise["level" + new_level];
      for (var param in level_config) {
        Vue.set(this.localValue.config, param, level_config[param]);
      }

      // If the max-level is less than the current level, set it to the current level
      if (this.localValue.max_level <= new_level) {
        this.localValue.max_level = new_level;
      }

      this.$forceUpdate();
      this.$emit("change", this.localValue);
    },

    // Enforce min-max and allowed values
    enforce_allowed_values() {
      let exercise = this.exercises[this.localValue.exercise_id];
      let default_config = JSON.parse(JSON.stringify(exercise.global));
      if (exercise.params) {
        for (const [key, param_config] of Object.entries(exercise.params)) {
          if (this.localValue.config[key]) {
            if (param_config.min !== null && this.localValue.config[key] < param_config.min) {
              this.localValue.config[key] = param_config.min;
            }
            if (param_config.max !== null && this.localValue.config[key] > param_config.max) {
              this.localValue.config[key] = param_config.max;
            }
            if (param_config.allowed !== null && !param_config.allowed.includes(this.localValue.config[key])) {
              this.localValue.config[key] = default_config[key];
              if (
                exercise.num_levels &&
                this.localValue.config.level &&
                this.exercise["level" + this.localValue.config.level] &&
                this.exercise["level" + this.localValue.config.level][key] !== undefined
              ) {
                this.localValue.config[key] = exercise["level" + this.localValue.config.level][key];
              }
            }
          }
        }
      }
    },

    save() {
      // Enforce allowed values and min / max
      this.enforce_allowed_values();

      // Check for errors
      this.errors = [];
      let exercise = this.$refs.exerciseConfig;

      if (typeof exercise.validate == "function") {
        this.errors = exercise.validate(this.value.config);
      }

      if (this.errors.length) {
        return;
      }

      this.$emit("save");
    },
  },
};
</script>

<style>
/* RTL languages */
html[lang="he"] .ql-editor,
html[lang="ar"] .ql-editor {
  text-align: right;
}
</style>
