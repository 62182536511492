<template>
  <v-card>
    <v-card-text>
      <v-container>
        <v-text-field v-model="localValue.title" outlined :label="$t('common.title')" type="text" />

        <p>{{ $t("common.instructions") }}</p>
        <quillEditor v-model="localValue.instructions" :options="quill_config" style="height: 140px; margin-bottom: 20px" />
      </v-container>

      <VuetifySurveyEditor
        v-model="localValue.questionnaire"
        class="mt-6"
        :allow_edit_title="false"
        :allow_edit_instructions="false"
        :allow_edit_visible="false"
        :show_item_id="false"
        :allow_edit_item_class="false"
        :allow_edit_item_style="false"
        :allow_edit_item_value="false"
        :disallow_same_option_value="true"
        :add_item_text="$t('regimen_questionnaire_edit.add_question')"
      />
    </v-card-text>

    <v-alert v-if="error_message" outlined type="error" elevation="2" class="mr-6 ml-6">{{ error_message }}</v-alert>

    <v-card-actions>
      <v-spacer />
      <v-btn color="primary" @click="save">{{ $t("common.save") }}</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";

import { quillEditor } from "vue-quill-editor";

import VuetifySurveyEditor from "./vuetify-survey/components/VuetifySurveyEditor.vue";

export default {
  components: { quillEditor, VuetifySurveyEditor },
  props: {
    value: {
      type: Object,
      required: true,
    },
  },
  data() {
    let data = {
      quill_config: {
        placeholder: this.$t("common.instructions"),
        modules: {
          toolbar: [
            ["bold", "italic", "underline", "strike"], // toggled buttons
            ["blockquote", "code-block"],

            [{ header: 1 }, { header: 2 }], // custom button values
            [{ list: "ordered" }, { list: "bullet" }],

            ["clean"], // remove formatting button
          ],
        },
      },
      error_message: "",
    };
    return data;
  },
  computed: {
    localValue: {
      get() {
        let value = this.value;
        value.questionnaire = value.questionnaire || {};
        return value;
      },
      set(localValue) {
        if (!localValue.questionnaire) {
          localValue.questionnaire = {};
        }
        this.$emit("input", localValue);
      },
    },
  },
  methods: {
    save() {
      if (!this.localValue.questionnaire || this.localValue.questionnaire.items.length == 0) {
        this.error_message = this.$t("regimen_questionnaire_edit.error_no_questions");
        return;
      }
      if (!this.localValue.title) {
        this.error_message = this.$t("regimen_questionnaire_edit.error_no_title");
        return;
      }

      this.$emit("save");
    },
  },
};
</script>

<style>
#invite_edit_modal .v-messages__message {
  line-height: normal;
}

.ql-editor {
  font-size: large;
}

.theme--dark .ql-snow .ql-stroke {
  stroke: gray;
}

.theme--light .ql-editor p {
  color: black;
}

/* RTL languages */
html[lang="he"] .ql-editor,
html[lang="ar"] .ql-editor {
  text-align: right;
}
</style>
