<template>
  <v-card>
    <v-card-title style="padding: 0px">
      <v-toolbar grey flat>
        <v-btn icon @click="close">
          <v-icon>close</v-icon>
        </v-btn>
        <v-toolbar-title>{{ $t("common.perceptual_speed") }}</v-toolbar-title>
      </v-toolbar>
    </v-card-title>
    <v-card-text>
      <v-layout align-center justify-space-around row>
        <v-flex xs11>
          <v-select v-model="chars" :items="chars_options" :label="$t('tool_hart_chart.characters')" />
        </v-flex>
      </v-layout>

      <v-layout align-center justify-space-around row>
        <v-flex xs5>
          <v-select v-model="font_size" :items="font_size_options" :label="$t('common.font_size')" />
        </v-flex>

        <v-flex xs5>
          <v-select v-model="num_choices_per_group" :label="$t('common.number_of_targets')" :items="num_choices_per_group_options" />
        </v-flex>
      </v-layout>

      <v-layout align-center justify-space-around row>
        <v-flex xs11>
          <v-select v-model="font" :items="fonts_options" :label="$t('common.font')" />
        </v-flex>
      </v-layout>
    </v-card-text>

    <v-card-actions>
      <v-btn @click="view()">{{ $t("common.view") }}</v-btn>
      <v-spacer />
      <v-btn color="primary" @click="print()">{{ $t("common.print") }}</v-btn>
    </v-card-actions>
    <v-progress-linear v-if="in_flight" :indeterminate="true" />
  </v-card>
</template>

<script>
import printJS from "print-js";
import { Chance } from "chance";
let chance = Chance();

export default {
  components: {},
  props: {},
  data() {
    let data = {
      font_size: 16,
      font: "Arial",
      chars: "lowerletters",
      num_choices_per_group: 4,
      num_choices_per_group_options: [3, 4],
      chars_options: [
        {
          text: this.$t("tool_hart_chart.letters_only"),
          value: "lowerletters",
        },
        {
          text: this.$t("tool_hart_chart.numbers_and_letters"),
          value: "lowerboth",
        },
        { text: this.$t("tool_hart_chart.numbers_only"), value: "numbers" },
      ],
      fonts_options: [
        { text: "Arial", value: "Arial" },
        { text: "Optical Sans", value: "OpticalSans" },
        { text: "Courier", value: "Courier" },
        { text: "Helvetica", value: "Helvetica" },
        { text: "Times New Roman", value: "Times New Roman" },
        { text: "Georgia", value: "Georgia" },
        { text: "Comic Sans", value: "Comic Sans MS" },
      ],
      font_size_options: [14, 16, 18, 20, 30, 40],
      in_flight: false,
    };
    return data;
  },
  methods: {
    view: function () {
      window.open(this.url(), "_blank");
    },
    url: function () {
      // Caculate num_cols and num_groups
      let num_cols, num_groups;

      switch (this.font_size) {
        case 14:
          num_cols = 2;
          num_groups = 40;
          break;
        case 16:
          num_cols = 2;
          num_groups = 38;
          break;
        case 18:
          if (this.num_choices_per_group === 4) {
            num_cols = 1;
            num_groups = 18;
          } else {
            num_cols = 2;
            num_groups = 36;
          }
          break;
        case 20:
          num_cols = 1;
          num_groups = 16;
          break;
        case 30:
          num_cols = 1;
          num_groups = 14;
          break;
        case 40:
          num_cols = 1;
          num_groups = 12;
          break;
        default:
          throw new Error("Invalid font size");
      }

      // If the font is over 18 and is comic sans, reduce the font-size slightly
      let font_size = this.font_size;
      if (this.font === "Comic Sans MS") {
        if (this.font_size == 16) {
          num_groups = 36;
        }
        if (this.font_size == 18) {
          font_size = 17;
        } else if (this.font_size == 30) {
          font_size = 26;
        } else if (this.font_size == 40) {
          font_size = 36;
        }
      }

      let uri = "/api/tools/pdf/perceptual_speed.pdf";
      uri += "?num_cols=" + num_cols;
      uri += "&num_groups=" + num_groups;
      uri += "&font_size=" + font_size;
      uri += "&num_choices_per_group=" + this.num_choices_per_group;
      uri += "&chars=" + this.chars;
      uri += "&font=" + encodeURIComponent(this.font);
      uri += "&cache_buster=" + chance.hex();

      return uri;
    },
    print: function () {
      let self = this;

      if (navigator.vendor.includes("Apple")) {
        window.open(this.url(), "_blank");
        return;
      }

      printJS({
        printable: self.url(),
        onLoadingStart: function () {
          self.in_flight = true;
        },
        onLoadingEnd: function () {
          self.in_flight = false;
        },
      });
    },
    close: function () {
      this.$emit("close");
    },
  },
};
</script>

<style></style>
