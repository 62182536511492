<template>
  <v-card>
    <v-card-title style="padding: 0px">
      <v-toolbar grey flat>
        <v-btn icon @click="close">
          <v-icon>close</v-icon>
        </v-btn>
        <v-toolbar-title>{{ $t("common.circle_square") }}</v-toolbar-title>
      </v-toolbar>
    </v-card-title>
    <v-divider />
    <v-card-text>
      <v-container>
        <v-select v-model="corpus" :items="corpus_option" :label="$t('tool_race_track_circle_square.line_thickness')" />

        <v-checkbox v-model="shapes" :label="$t('common.circle')" value="circle" hide-details></v-checkbox>
        <v-checkbox v-model="shapes" :label="$t('common.square')" value="square" hide-details></v-checkbox>
        <v-checkbox v-model="shapes" :label="$t('common.triangle')" value="triangle" hide-details></v-checkbox>
        <v-checkbox v-model="shapes" label="X" value="x" hide-details></v-checkbox>
      </v-container>
    </v-card-text>

    <v-card-actions>
      <v-btn @click="view()">{{ $t("common.view") }}</v-btn>
      <v-btn @click="download()">{{ $t("common.download") }}</v-btn>
      <v-spacer />
      <v-alert v-if="too_few_shapes_error" type="error" outlined dense>{{ $t("tool_circle_square.please_select_two_options") }}</v-alert>
      <v-spacer />
      <v-btn color="primary" @click="print()">{{ $t("common.print") }}</v-btn>
    </v-card-actions>
    <v-progress-linear v-if="in_flight" :indeterminate="true" />
  </v-card>
</template>

<script>
import printJS from "print-js";
import { Chance } from "chance";
let chance = Chance();

export default {
  components: {},
  props: {},
  data() {
    let data = {
      corpus: "cstx_thin",
      corpus_option: [
        {
          text: this.$t("tool_race_track_circle_square.thin"),
          value: "cstx_thin",
        },
        { text: this.$t("tool_race_track_circle_square.thick"), value: "cstx" },
      ],
      shapes: ["circle", "square"],
      too_few_shapes_error: false,
      in_flight: false,
    };
    return data;
  },
  methods: {
    url(rotate = false, download = false) {
      let uri = "/api/tools/pdf/circle_square.pdf";
      uri += "?shapes=" + this.shapes.join(",");
      uri += "&corpus=" + this.corpus;
      uri += "&cache_buster=" + chance.hex();

      if (rotate) {
        uri += "&rotate=90";
      } else {
        uri += "&margin_top=20";
      }
      if (download) {
        uri += "&download=true";
      }

      return uri;
    },
    view: function () {
      this.too_few_shapes_error = false;
      if (this.shapes.length < 2) {
        this.too_few_shapes_error = true;
        return;
      }
      window.open(this.url(true), "_blank");
    },
    download() {
      this.too_few_shapes_error = false;
      if (this.shapes.length < 2) {
        this.too_few_shapes_error = true;
        return;
      }
      window.open(this.url(true, true), "_blank");
    },
    print: function () {
      this.too_few_shapes_error = false;
      if (this.shapes.length < 2) {
        this.too_few_shapes_error = true;
        return;
      }

      if (navigator.vendor.includes("Apple")) {
        window.open(this.url(), "_blank");
        return;
      }

      let self = this;
      printJS({
        printable: self.url(true),
        onLoadingStart: function () {
          self.in_flight = true;
        },
        onLoadingEnd: function () {
          self.in_flight = false;
        },
        style: "@page { size: Letter Landscape; }",
      });
    },
    close: function () {
      this.$emit("close");
    },
  },
};
</script>
