<template>
  <div class="clinic-page-invite-line" style="display: flex; flex-direction: row; justify-content: space-between; padding: 10px">
    <!-- Avatar, Name and email -->
    <div :style="dense ? 'width: 300' : 'width: 325px'">
      <div v-if="!$vuetify.rtl" style="float: left" class="invite_line_avatar_container">
        <v-list-item-avatar class="mb-0 mt-1">
          <v-icon class="grey lighten-1 white--text">email</v-icon>
        </v-list-item-avatar>
      </div>
      <div style="width: 265px" class="invite_line_name_container">
        <div style="user-select: all">{{ invite.name }} {{ invite.name_family }}</div>
        <div style="white-space: pre-line; max-height: 40px; user-select: all; font-size: 0.9rem">{{ invite.email }}</div>
        <div v-if="invite.user_role != 'user'">{{ $t("clinic_page_invite_line.staff_role") }}: {{ invite.user_role }}</div>
      </div>
    </div>

    <!-- Assigned to staff -->
    <div v-if="!hide_assigned && show_assignment" style="width: 180px; font-size: 0.9rem">
      <div v-if="owner && invite.user_role == 'user'">
        <div v-if="owner.assigned" style="font-size: 0.8rem">
          {{ $t("common.assigned_to", { name_given: owner.name_given, name_family: owner.name_family }) }}
        </div>
        <div v-else style="font-style: italic">{{ $t("common.unassigned") }}</div>
      </div>
    </div>

    <!-- Token -->
    <div :style="dense ? 'width: 290px' : 'width: 350px'">
      <div :style="'white-space: pre-line; max-height: 40px;' + (x_dense ? 'font-size: 0.8rem;' : 'font-size: 0.9rem;')">
        {{ $t("clinic_page_invite_line.waiting_for_user") }}
      </div>
      <div style="font-size: 0.9rem">
        <span v-if="!x_dense">{{ $t("clinic_page_invite_line.invitation_code") }}:&nbsp;</span>
        <pre style="display: inline; user-select: all">{{ invite.token }}-{{ invite.token_secret }}</pre>
        <v-icon v-if="!invite_print_inflight" small right class="regimen_print_icon" @click="print_invite">print</v-icon>
        <v-progress-circular v-if="invite_print_inflight" indeterminate size="16" style="margin-left: 10px" />
      </div>
    </div>

    <!-- Notifications -->
    <div style="width: 250px; overflow: visible">
      <div v-if="invite.email_status">
        <v-chip v-if="invite.email_status == 'delivered'" color="light-green" text-color="white" small>{{
          $t("clinic_page_invite_line.email_delivered")
        }}</v-chip>
        <v-chip v-if="invite.email_status == 'opened'" color="green" text-color="white" small>{{ $t("clinic_page_invite_line.email_opened") }}</v-chip>
        <v-chip v-if="invite.email_status == 'clicked'" color="green" text-color="white" small>{{
          $t("clinic_page_invite_line.accept_invitation_link_clicked")
        }}</v-chip>
        <v-chip v-if="invite.email_status == 'permanent_fail'" color="light-red" text-color="white" small>{{
          $t("clinic_page_invite_line.email_delivery_failed")
        }}</v-chip>
        <v-chip v-if="invite.email_status == 'temporary_fail'" color="grey" text-color="white" small>{{
          $t("clinic_page_invite_line.email_delivery_in_progress")
        }}</v-chip>
        <v-chip v-if="invite.email_status == 'complained'" color="light-red" text-color="white" small>{{
          $t("clinic_page_invite_line.email_marked_as_spam")
        }}</v-chip>
        <v-chip v-if="invite.email_status == 'unsubscribed'" color="light-red" text-color="white" small>{{
          $t("clinic_page_invite_line.user_unsubscribed_from_ocudigital_emails")
        }}</v-chip>
      </div>
    </div>

    <!-- Actions -->
    <div :style="'width: 300px; ' + ($vuetify.rtl ? 'text-align: left; margin-left: 10px;' : 'text-align: right; margin-right: 10px;')">
      <v-tooltip v-if="invite.user_role == 'user' || current_user_role == 'admin'" top open-delay="500">
        <template v-slot:activator="{ on }">
          <v-btn
            :small="!dense"
            :x-small="dense"
            outlined
            fab
            :tile="dense"
            color="green"
            :class="{ invite_line_action_button: !dense, invite_line_action_button_dense: dense }"
            v-on="on"
            @click="invite_dialog = true"
          >
            <v-icon>edit</v-icon>
          </v-btn>
        </template>
        {{ $t("clinic_page_invite_line.edit_invitation") }}
      </v-tooltip>

      <v-tooltip v-if="show_regimen_edit && invite.user_role == 'user'" top open-delay="500">
        <template v-slot:activator="{ on }">
          <v-btn
            :small="!dense"
            :x-small="dense"
            outlined
            fab
            :tile="dense"
            color="teal"
            :class="{ invite_line_action_button: !dense, invite_line_action_button_dense: dense }"
            v-on="on"
            @click="regimen_dialog = true"
          >
            <v-icon>list</v-icon>
          </v-btn>
        </template>
        {{ $t("clinic_page_invite_line.assign_exercises") }}
      </v-tooltip>

      <v-tooltip v-if="invite.user_role == 'user' || current_user_role == 'admin'" top open-delay="500">
        <template v-slot:activator="{ on }">
          <v-btn
            :small="!dense"
            :x-small="dense"
            outlined
            fab
            :tile="dense"
            color="red"
            :class="{ invite_line_action_button: !dense, invite_line_action_button_dense: dense }"
            v-on="on"
            @click="delete_invite"
          >
            <v-icon>delete</v-icon>
          </v-btn>
        </template>
        {{ $t("clinic_page_invite_line.delete_invitation") }}
      </v-tooltip>

      <slot name="action"></slot>
    </div>

    <v-dialog v-model="regimen_dialog" scrollable max-width="1200" :transition="false">
      <RegimenEdit
        v-if="regimen_dialog"
        ref="regimen_edit"
        :invite_id="invite.id"
        :invite_accepted="invite.user_id ? true : false"
        :user_name="invite.email"
        :current_user="current_user"
        :clinic="clinic"
        :exercises="exercises"
        :video_exercises="video_exercises"
        :staff="clinic_staff"
        :invite_owner_id="owner && owner.id ? owner.id : ''"
        :grid_sets="grid_sets"
        :grid_schedules="grid_schedules"
        @close="regimen_dialog = false"
        @regimen-created="$emit('regimen_created')"
        @regimen-updated="$emit('regimen_updated')"
      />
    </v-dialog>

    <v-dialog v-model="invite_dialog" max-width="1000" :transition="false">
      <InviteEdit
        v-if="invite_dialog"
        :resend_on_update="invite_edit_resend_on_update"
        :allow_user_role_edit="allow_user_role_edit"
        :invite="invite"
        :clinic="clinic"
        :current_user="current_user"
        :allow_edit_send_email="invite_edit_allow_edit_send_email"
        :default_send_email="invite_edit_default_send_email"
        @close-invite-edit="invite_dialog = false"
        @invite-updated="invite_updated"
      />
    </v-dialog>
  </div>
</template>

<script>
import printJS from "print-js";
import { Chance } from "chance";
let chance = Chance();
import InviteEdit from "./InviteEdit";
import RegimenEdit from "./RegimenEdit";
import { mapState } from "vuex";

export default {
  components: {
    InviteEdit,
    RegimenEdit,
  },
  props: {
    invite: {
      type: Object,
      required: true,
    },
    current_user: {
      type: Object,
      required: true,
    },
    current_user_role: {
      type: String,
      required: true,
    },
    clinic: {
      type: Object,
      required: true,
    },
    exercises: {
      type: Object,
      required: true,
    },
    video_exercises: {
      type: Object,
      required: true,
    },
    clinic_staff: {
      type: Array,
      required: true,
    },
    owner: {
      type: Object,
      validator: function (value) {
        return typeof value == "object" || typeof value == "undefined";
      },
      default: () => undefined,
    },
    allow_user_role_edit: {
      type: Boolean,
      default: () => false,
    },
    hide_assigned: {
      type: Boolean,
      default: () => false,
    },
    grid_sets: {
      type: Array,
      required: true,
    },
    grid_schedules: {
      type: Array,
      required: true,
    },
    dense: {
      type: Boolean,
      default: () => false,
    },
    x_dense: {
      type: Boolean,
      default: () => false,
    },
    show_assignment: {
      type: Boolean,
      default: () => true,
    },
    show_regimen_edit: {
      type: Boolean,
      default: () => true,
    },
    invite_edit_allow_edit_send_email: {
      type: Boolean,
      default: () => false,
    },
    invite_edit_default_send_email: {
      type: Boolean,
      default: () => true,
    },
    invite_edit_resend_on_update: {
      type: Boolean,
      default: () => true,
    },
  },
  data() {
    return {
      invite_dialog: false,
      regimen_dialog: false,
      invite_print_inflight: false,
    };
  },
  computed: {
    ...mapState(["small_screen"]),
  },
  methods: {
    print_invite: function () {
      let self = this;

      let uri = "/api/invite/" + this.invite.id + "/print?lang=" + this.$i18n.lang() + "&cache_buster=" + chance.hex();

      printJS({
        printable: uri,
        onLoadingStart: function () {
          self.invite_print_inflight = true;
        },
        onLoadingEnd: function () {
          self.invite_print_inflight = false;
        },
      });
    },

    delete_invite() {
      this.$emit("delete_invite");
    },
    invite_updated() {
      this.$emit("invite_updated");
    },
  },
};
</script>

<style>
.invite_line_action_button {
  margin-left: 10px;
}

.invite_line_action_button_dense {
  margin-left: 5px;
  margin-top: 8px;
}

.invite_line_name_container {
  float: right;
}

@media screen and (max-width: 1720px) {
  .invite_line_avatar_container {
    display: none !important;
  }
  .invite_line_name_container {
    float: none;
  }
}
</style>
