<template>
  <v-card>
    <v-card-title style="padding: 0px">
      <v-toolbar grey flat>
        <v-btn icon @click="close">
          <v-icon>close</v-icon>
        </v-btn>
        <v-toolbar-title>{{ $t("common.ladybug_fixation") }}</v-toolbar-title>
      </v-toolbar>
    </v-card-title>
    <v-divider />
    <v-card-text>
      <v-container>
        <v-select v-model="picture" :items="picture_options" :label="$t('tool_lady_bug.picture')" />
      </v-container>
    </v-card-text>

    <v-card-actions>
      <v-btn @click="view()">{{ $t("common.view") }}</v-btn>
      <v-spacer />
      <v-btn color="primary" @click="print()">{{ $t("common.print") }}</v-btn>
    </v-card-actions>
    <v-progress-linear v-if="in_flight" :indeterminate="true" />
  </v-card>
</template>

<script>
import printJS from "print-js";
import { Chance } from "chance";
let chance = Chance();

export default {
  components: {},
  props: {},
  data() {
    let data = {
      picture: "lady_bug_fixation_lady_bug",
      picture_options: [
        {
          text: this.$t("tool_lady_bug.lady_bug"),
          value: "lady_bug_fixation_lady_bug",
        },
        {
          text: this.$t("tool_lady_bug.monster"),
          value: "lady_bug_fixation_monster",
        },
      ],
      in_flight: false,
    };
    return data;
  },
  methods: {
    view: function () {
      window.open(this.url(), "_blank");
    },
    url: function () {
      let uri = "/api/tools/pdf/picture_grid/";
      uri += this.picture;
      uri += "/picture_grid.pdf?rows=7&cols=7&size=90&padding_sides=0&padding_tottom=20";
      uri += "&cache_buster=" + chance.hex();

      return uri;
    },
    print: function () {
      let self = this;

      if (navigator.vendor.includes("Apple")) {
        window.open(this.url(), "_blank");
        return;
      }

      printJS({
        printable: self.url(),
        onLoadingStart: function () {
          self.in_flight = true;
        },
        onLoadingEnd: function () {
          self.in_flight = false;
        },
        style: "@page { size: Letter landscape; }",
      });
    },
    close: function () {
      this.$emit("close");
    },
  },
};
</script>
