<template>
  <v-card>
    <v-card-title style="padding: 0px">
      <v-toolbar grey flat>
        <v-btn icon @click="close">
          <v-icon>close</v-icon>
        </v-btn>
        <v-toolbar-title>{{ $t("tool_circle_triangle.circle_triangle") }}</v-toolbar-title>
      </v-toolbar>
    </v-card-title>
    <v-divider />
    <v-card-text>
      <v-container>
        <v-select v-model="picture" :items="picture_options" :label="$t('tool_circle_triangle.single_double')" />
      </v-container>
    </v-card-text>

    <v-card-actions>
      <v-btn @click="view()">{{ $t("common.view") }}</v-btn>
      <v-btn @click="download()">{{ $t("common.download") }}</v-btn>
      <v-spacer />
      <v-btn color="primary" @click="print()">{{ $t("common.print") }}</v-btn>
    </v-card-actions>
    <v-progress-linear v-if="in_flight" :indeterminate="true" />
  </v-card>
</template>

<script>
import printJS from "print-js";
import { Chance } from "chance";
let chance = Chance();

export default {
  components: {},
  props: {},
  data() {
    let data = {
      picture: "circle_triange_single",
      picture_options: [
        {
          text: this.$t("tool_circle_triangle.circle_triangle_singles"),
          value: "circle_triange_single",
        },
        {
          text: this.$t("tool_circle_triangle.circle_triangle_doubles"),
          value: "circle_triange_double",
        },
      ],
      in_flight: false,
    };
    return data;
  },
  methods: {
    view() {
      window.open(this.url(true, false), "_blank");
    },
    download() {
      window.open(this.url(false, true), "_blank");
    },
    url(rotate = false, download = false) {
      let uri = "/api/tools/pdf/picture_grid/";
      uri += this.picture;
      uri += "/picture_grid.pdf?rows=8&cols=5&size=128&padding_sides=25&padding_tottom=6";
      uri += "&margin_top=25";
      if (rotate) {
        uri += "&rotate=90";
      }
      uri += "&cache_buster=" + chance.hex();

      if (download) {
        uri += "&download=true";
      }

      return uri;
    },
    print: function () {
      let self = this;

      if (navigator.vendor && navigator.vendor.includes("Apple")) {
        window.open(this.url(), "_blank");
        return;
      }

      printJS({
        printable: this.url(true),
        onLoadingStart: function () {
          self.in_flight = true;
        },
        onLoadingEnd: function () {
          self.in_flight = false;
        },
        style: "@page { size: Letter portrait; }",
      });
    },
    close: function () {
      this.$emit("close");
    },
  },
};
</script>
